import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
  }

  previous(event) {
    $('.wizard-wrapper').hide()
    $(`#${event.params.previousStep}`).show();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  next(event) {
    $('.validation-message').hide();
    let isValid = this.validateForm(event.params.currentStep);
    if (!isValid) {
      $('.validation-message').show();
    } else {
      $('.wizard-wrapper').hide()
      $(`#${event.params.nextStep}`).show();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  toggle(event) {
    $(`#coach-wrapper-${event.params.coach}`).toggle();
  }

  validateForm(step) {
    let isValid = true;
    const requiredFields = [];
    const escapedStepId = CSS.escape(step);
    const requiredFieldSelectors = `#${escapedStepId} textarea.pulse-field-required, #${escapedStepId} input.pulse-field-required`;

    document.querySelectorAll(requiredFieldSelectors).forEach((element) => {
      if (window.getComputedStyle(element.parentNode?.parentNode).display === 'none') return;
      requiredFields.push(element);
    });

    if (requiredFields.length > 0) {
      requiredFields.forEach((field) => {
        if ($(`input[id='${field.id}']`).is(':hidden')) {
          return;
        }
        if (field.type == 'radio') {
          const containerElement = field.closest('.table') || field.closest('.form-group.required');

          if ($(`input[name='${field.name}']:checked`).length < 1) {
            containerElement.classList.add('is-invalid');
            isValid = false;
            console.log(`Failed validation from Radio field ${field.name}`)
          } else {
            containerElement.classList.remove('is-invalid');
          }
        } else {
          if (!field.disabled && $(field).is(':visible') != 'none' && (!field.value.trim())) {
            field.classList.add('is-invalid');
            isValid = false;
            console.log(`Failed validation from ${field.type} field ${field.name}`)
          } else {
            field.classList.remove('is-invalid');
          }
        };
      });
    }

    return isValid;
  }

}
