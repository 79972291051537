import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['link'];

  async send(event) {
    event.preventDefault();

    const url = this.linkTarget.href;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content }
      })

      if (response.ok) {
        alert('Your reminder has been sent');
      }
    } catch (error) {
      console.error('Error', error);
    }
  }
}
