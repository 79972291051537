// noinspection JSUnresolvedReference

import { Controller } from '@hotwired/stimulus';

/**
 * The primary function of bulk_collection is to accept an array of DOM elements when supplied by table_select, and convert
 * that array of DOM elements into an array of *_ids (e.g. student_ids, task_ids, etc.) that will eventually be consumed
 * by a POST request.
 */
export default class extends Controller {
  /**
   * Accept the array of selected rows provided by `table_select_controller.js` and add each element's data-id attribute
   * value to an object
   *
   * @param content
   */
  collectRows({ detail: { content } }) {
    this.options = {
      ids: content.map(el => el.dataset.id)
    };
  }

  collectAppointmentRequestRows({ detail: { content }}) {
    this.options = {
      ids: content.map(el => el.dataset.id),
      request_ids: content.map(el => el.dataset.appointmentRequestId),
      appointment_type_ids: content.map(el => el.dataset.appointmentTypeId),
      course_ids: content.map(el => el.dataset.courseId),
    };
  }

  collectAppointmentCampaignRecipientRows({ detail: { content }}) {
    this.options = {
      ids: content.map(el => el.dataset.id),
      slugs: content.map(el => el.dataset.slug),
      appointment_type_ids: content.map(el => el.dataset.appointmentTypeId),
      appointment_campaign_ids: content.map(el => el.dataset.appointmentCampaignId)
    };
  }

  /**
   * Trigger an event to hand the options object to a different controller.
   */
  emitCollection(event) {
    this.options.dataSet = event.target.dataset;
    this.dispatch('sendOptions', { detail: { content: this.options } });
  }
}
